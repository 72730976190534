import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    classify: state.classify,
    longDialogShowPC: state.longDialogShowPC,
    token: state.token, // 这个就是存入localStorage的值 vuex存储在内存，localstorage则以文件的方式存储在本地 可以自动将 Vuex的 store 数据本地持久化存储
    userInfo: state.userInfo,
  }),
});

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    curRoute: "", // 当前路由
    paramsId: "", // 当前路由params传参id
    longDialogShowPC: false,
    token: null,
    userInfo: {},
  },
  actions: {},
  mutations: {
    updateCurRoute(state, curRoute) {
      state.curRoute = curRoute;
    },
    updateParamsId(state, paramsId) {
      state.paramsId = paramsId;
    },
    updateLongDialogShowPC(state, longDialogShowPC) {
      state.longDialogShowPC = longDialogShowPC;
    },
    updateToken(state, token) {
      state.token = token;
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  getters: {},
  plugins: [vuexLocal.plugin],
});

export default store;
