<template>
  <div id="app" ref="app">
    <div v-if="$route.name === 'metaverseMobile'">
      <router-view to="/metaverseMobile"/>
    </div>
    <div v-else-if="$route.name === 'contractSpecification'">
      <router-view to="/contractSpecification"/>
    </div>
    <div v-else-if="$route.name === 'imgPreview'">
      <router-view to="/imgPreview"/>
    </div>
    <el-container v-else>
      <myheader> </myheader>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <myfooter></myfooter>
      </el-footer>
      <sideList></sideList>
    </el-container>
  </div>
</template>
<script>
import myfooter from "./components/footer";
import sideList from "./components/sideList";
import myheader from "./components/header";
import { component as VueLazyComponent } from "@xunlei/vue-lazy-component";

export default {
  components: {
    myfooter,
    sideList,
    myheader,
    // eslint-disable-next-line vue/no-unused-components
    "vue-lazy-component": VueLazyComponent,
  },
  data() {
    return {
    };
  },
  methods: {
    routeComponent() {

    }
  }
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  margin: 0 !important;
  margin: 0px;
  margin-bottom: 0;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-container {
  padding: 0;
}

.el-main {
  padding: 0 !important;
  width: 100% !important;
  min-height: 100vh !important;
  min-width: 1150px;
}

.el-footer {
  padding: 0 !important;
}

a {
  color: inherit;
}

/*##################################################### _base.scss START #####################################################*/
/*  @font-face {
 font-family: 'iconfont';
 src: url("/static/css/Fonts/iconfont/iconfont.eot");
 src: url("/static/css/Fonts/iconfont/iconfont.woff") format("woff"), url("/static/css/Fonts/iconfont/iconfont.ttf") format("truetype"), url("/static/css/Fonts/iconfont/iconfont.svg") format("svg");
 font-weight: normal;
 font-style: normal; } */
div {
  background-repeat: no-repeat;
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  color: inherit;
  font-size: inherit;
}

THEAD,
TBODY,
TFOOT {
  display: table-row-group;
  width: 100%;
}

TR {
  width: 100%;
  display: table-row;
}

TH {
  font-weight: normal;
  padding: 0;
  vertical-align: top;
  text-align: left;
  display: table-cell;
}

TD {
  padding: 0;
  vertical-align: top;
  display: table-cell;
}

UL {
  padding: 0;
  margin: 0;
}

LI {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

INPUT,
BUTTON {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit;
}

select {
  box-sizing: border-box;
}

P {
  margin: 0;
}

IMG {
  border-width: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

em,
i {
  font-style: normal;
}

a {
  text-decoration: none;
}

.textAlign_left {
  text-align: left !important;
}

.textAlign_center {
  text-align: center !important;
}

.textAlign_right {
  text-align: right !important;
}

/*===================================================== _base.scss END =====================================================*/
BODY {
  font-family: "Microsoft Yahei", "微软雅黑";
  font-size: 14px;
}

BODY {
  margin: 0;
  padding: 0;
}

HEADER,
FOOTER {
  display: block;
}

.input-submit,
.input-button {
  line-height: 3.1em;
  font-size: 16px;
  letter-spacing: 1px;
  padding-left: 1.8em;
  padding-right: 1.8em;
  border-width: 0;
  color: #ffffff;
  border: 1px solid;
  outline: 0;
  border-color: #2c9b84 #207362 #207362 #2c9b84;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 1px 0 rgba(255, 255, 255, 0.15),
    1px 1px 2px rgba(0, 0, 0, 0.25), -1px -1px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0.3em;
  background-image: -moz-linear-gradient(to top,
      rgba(46, 163, 139, 0.8),
      rgba(30, 107, 91, 0.8));
  background-image: -ms-linear-gradient(to top,
      rgba(46, 163, 139, 0.8),
      rgba(30, 107, 91, 0.8));
  background-image: -webkit-linear-gradient(to top,
      rgba(46, 163, 139, 0.8),
      rgba(30, 107, 91, 0.8));
  background-image: linear-gradient(to top,
      rgba(46, 163, 139, 0.8),
      rgba(30, 107, 91, 0.8));
  background-color: #268773;
  background-repeat: no-repeat;
  transition: background-color 0.2s linear;
}

.input-submit:hover,
.input-button:hover {
  background-color: #154b40;
}

.input-text,
.input-password {
  border: 1px solid;
  border-color: #dae3e8;
  line-height: 40px;
  height: 40px;
  width: 336px;
  padding-left: 4px;
  color: #6293c8;
  font-size: 14px;
}

.input-select {
  width: auto;
  line-height: 26px;
  height: 26px;
}

.input-number {
  width: 60px;
  text-align: center;
  padding-left: 0;
}

.input-verification {
  width: 50px;
}

/* .input-date {
  background-image: url(/PC/scripts/LayDate/icon.gif);
  background-position: right center;
  width: 100px;
} */

/*# sourceMappingURL=global.css.map */
DIV,
H1,
H2,
H3,
H4,
H5,
H6,
TD,
TH,
I,
LI,
IMG,
STRONG,
EM,
SPAN,
A {
  background-repeat: no-repeat;
  margin: 0;
}
</style>
<style lang="scss"></style>
