<template>
  <div class="el-header">
    <div class="haed-conten">
      <div class="logo"></div>
      <div class="header-r">
        <el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <template v-for="menuItem in menuList">
            <!-- 一级标题无子菜单 -->
            <el-menu-item v-if="!menuItem.children" :index="menuItem.name">
              <span slot="title">{{menuItem.name}}</span>
            </el-menu-item>
            <!-- 一级标题有子菜单 -->
            <el-submenu v-else :index="menuItem.name">
              <span style="color: #fff !important;
              font-size: 18px;" slot="title">{{menuItem.name}}</span>
              <!-- 二级标题 -->
              <el-menu-item v-for="children in menuItem.children" :index="children.name">
                {{children.name}}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myfooter",
  props: ["data"],
  data() {
    return {
      defaultActive: "首页",
      url:"https://www.11xyz.com/it_kc_index",
      menuList: [
        { id: '1', name: '首页', path: '/', },
        { id: '2', name: '快车科技', path: '/aboutUs'},
        { id: '3', name: 'AI赋能', path: '/digitalEmpowerment'},
        { id: '4', name: '综合解决方案', path: '/', 
        children: [{ id: '4-1', name: '城市客厅', path: 'integratedSolution' }, { id: '4-2', name: '创新产品', path: '/innovativeProduct' }] 
        },
        { id: '5', name: '寻找超人', path: '/'},
      ],
    };
  },
  created() {
    this.defaultActive = this.$route.meta?.title ? this.$route.meta?.title : "首页"
  },
  methods: {
    handleSelect(key, keyPath) {
      let routes = this.$router.options.routes;
      let curRoute = routes.find(item => item.meta?.title == key);
      let targetPath = "";
      targetPath = curRoute.path;
      if (targetPath && this.$route.path !== targetPath) {
        this.$router.push({ path: targetPath, query: { id: curRoute?.id } });
      }
    }

  },
  watch: {
    $route(to, from) {
      this.defaultActive = to.meta?.title ? to.meta?.title : "首页"
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  width: 100%;
  height: 100px;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;

  .haed-conten {
    width: 1150px;
    margin: 0 auto;
    min-width: 1150px;
    display: flex;
    justify-content: space-between;
    align-content: flex-end;

  }

  //background-color: #fff;

  .logo {
    width: 280px;
    padding: 10px;
    flex: 1;
    background-image: url(../assets/icon/logo.png);
    background-size: auto;
    background-position: left center;

  }

  .header-r {
    height: 100px;
    display: flex;
    align-items: flex-end;


  }

  .el-menu {
    // height: 10px;

    background: transparent !important;
    border-bottom: 0 solid #000 !important;

    .el-menu-item {
      color: #fff !important;
      font-size: 18px;
      background: transparent !important;
      padding: 0;
      margin: 0 20px;
    }


  }

  .is-active {
    border-bottom: 2px solid #fffdfe !important;
  }

  ::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: none;
  }
  ::v-deep .el-submenu__title:hover{
    background: none!important;
  }
}
</style>